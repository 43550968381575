import moment from 'moment';
import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import store from './store';

// views
const LoginPage = () =>
  import(/* webpackChunkName: "group-user" */ './views/LoginPage.vue');
const DashboardPage = () =>
  import(/* webpackChunkName: "group-user" */ './views/DashboardPage.vue');
const DashboardComponent = () =>
  import(
    /* webpackChunkName: "group-user" */ './components/Dashboard/DashboardComponent.vue'
  );
const About = () =>
  import(/* webpackChunkName: "group-user" */ './views/About.vue');
// registrasi
const NewPatient = () =>
  import(
    /* webpackChunkName: "chunk-register-outpatient-dashboard" */ /* webpackPrefetch: true */ './components/Registration/NewPatient.vue'
  );
const Tracer = () => import('./components/Registration/Tracer.vue');
const NewPatientIGD = () =>
  import(
    /* webpackChunkName: "chunk-register-igd-dashboard" */ /* webpackPrefetch: true */ './components/Registration/IGD/NewIGDPatient.vue'
  );
const DoctorSchedule = () =>
  import('./components/Registration/DoctorSchedule.vue');
const BedStatusUpdate = () =>
  import('./components/Registration/BedStatusUpdate.vue');
const SettingDashboard = () =>
  import('./components/Registration/DoctorSchedule/SettingDashboard.vue');
const MainSetting = () =>
  import('./components/Registration/DoctorSchedule/MainSetting.vue');
const ChangeQuota = () =>
  import('./components/Registration/DoctorSchedule/ChangeQuota.vue');
const ChangeSchedule = () =>
  import('./components/Registration/DoctorSchedule/ChangeSchedule.vue');
// service
// const Hemodialysis = ()=>import ('./components/Service/Hemodialysis.vue');
// const MidwifeClinic = ()=>import ('./components/Service/MidwifeClinic.vue');
const GeneralOutpatientCare = () =>
  import(
    /* webpackChunkName: "chunk-general-assesment" */ /* webpackPrefetch: true */ './components/Service/OutpatientCareDashboard.vue'
  );
// const GeneralInpatientCare = ()=>import ('./components/Service/GeneralInpatientCare.vue');
const InpatientCare = () =>
  import(
    /* webpackChunkName: "chunk-inpatient-assesment" */ /* webpackPrefetch: true */ './components/Service/InpatientCare.vue'
  );
// const FisioTherapy = ()=>import ('./components/Service/FisioTherapy.vue');
// const EmergencyIgd = ()=>import ('./components/Service/EmergencyIgd.vue');
// const EyeClinic = ()=>import ('./components/Service/EyeClinic.vue');
// const TalkTherapy = ()=>import ('./components/Service/TalkTherapy.vue');
// const DentalClinic = ()=>import ('./components/Service/DentalClinic.vue');
// const PsychologyClinic = ()=>import ('./components/Service/PsychologyClinic.vue');
const NurseStation = () => import('./components/Service/NurseStation.vue');
const QueueDashboard = () =>
  import('./components/Service/GeneralComponent/QueueDashboard.vue');
// const OccupationTherapy = ()=>import ('./components/Service/OccupationTherapy.vue');
// const MedicalRehabilitation = ()=>import ('./components/Service/MedicalRehabilitation.vue');
const VKClinic = () => import('./components/Service/VKClinic.vue');

// pharmacy
const DrugHandover = () =>
  import(
    /* webpackChunkName: "chunk-pharmacy-drug-handover" */ /* webpackPrefetch: true */ './components/Pharmacy/SubMenu/DrugHandover.vue'
  );
const PharmacyMainMenu = () =>
  import(
    /* webpackChunkName: "chunk-main-pharmacy" */ /* webpackPrefetch: true */ './components/Pharmacy/PharmacyMainMenu.vue'
  );
const DrugReturns = () =>
  import('./components/Pharmacy/SubMenu/DrugReturns.vue');
const DrugsToolsRequest = () =>
  import(
    /* webpackChunkName: "chunk-pharmacy-drug-request" */ /* webpackPrefetch: true */ './components/Pharmacy/SubMenu/DrugsToolsRequest.vue'
  );
const DrugsCreate = () =>
  import(
    /* webpackChunkName: "chunk-pharmacy-drug-create" */ /* webpackPrefetch: true */ './components/Pharmacy/SubMenu/DrugsCreate.vue'
  );
const DrugQueue = () => import('./components/Pharmacy/SubMenu/DrugQueue.vue');
const DrugQueueNumber = () =>
  import('./components/Pharmacy/SubMenu/DrugQueueNumber.vue');
const DrugsSelling = () =>
  import('./components/Pharmacy/SubMenu/DrugsSelling.vue');
const DrugsReview = () =>
  import('./components/Pharmacy/SubMenu/DrugsReview.vue');
const DrugsReviewCPO = () =>
  import('./components/Pharmacy/SubMenu/DrugsReviewCPO.vue');
// support
const LabComponent = () =>
  import('./components/Penunjang/Lab/LabComponent.vue');
const RadiologyComponent = () =>
  import('./components/Penunjang/Radiologi/RadiologyComponent.vue');
const PathologyComponent = () =>
  import('./components/Penunjang/Pathology/PathologyComponent.vue');
const BloodRequestComponent = () =>
  import(
    './components/Penunjang/BloodTransfusion/BloodTransfusionComponent.vue'
  );
// emr
const EmrPage = () => import('./components/EMR/EmrPage.vue');
const EmrDashboardPage = () =>
  import('./components/EMR/Component/EmrDashboardPage.vue');
const EmrDashboardDataChange = () =>
  import('./components/EMR/Component/DashboardDataChange.vue');
// masterdata
const MasterDataStaff = () => import('./components/Master/MasterDataStaff.vue');
const MasterDataBed = () => import('./components/Master/MasterDataBed.vue');
const MasterDataRoom = () => import('./components/Master/MasterDataRoom.vue');
const MasterDataWard = () => import('./components/Master/MasterDataWard.vue');
const InputDataStaff = () =>
  import('./components/Master/Component/Staff/InputDataStaff.vue');
const MasterDataDiagnosa = () =>
  import('./components/Master/MasterDataDiagnosa.vue');
const InputDataDiagnosa = () =>
  import('./components/Master/Component/Diagnose/InputNewDiagnose.vue');
const MasterDataDrugs = () => import('./components/Master/MasterDataDrugs.vue');
const InputDataDrugs = () =>
  import('./components/Master/Component/Drugs/InputNewDrugs.vue');
const MasterDataGoods = () => import('./components/Master/MasterDataGoods.vue');
const InputDataGoods = () =>
  import('./components/Master/Component/Goods/InputNewGoods.vue');
const MasterDataProcedure = () =>
  import('./components/Master/MasterDataProcedure.vue');
const InputDataProcedure = () =>
  import('./components/Master/Component/Procedure/InputNewProcedure.vue');
const MasterDataUnit = () => import('./components/Master/MasterDataUnit.vue');
const InputDataUnit = () =>
  import('./components/Master/Component/Unit/InputNewUnit.vue');
const MasterDataIntervention = () =>
  import('./components/Master/MasterDataIntervention.vue');
const InputDataIntervention = () =>
  import('./components/Master/Component/Intervention/InputNewIntervention.vue');
const MasterDataSupplier = () =>
  import('./components/Master/MasterDataSupplier.vue');
const InputDataSupplier = () =>
  import('./components/Master/Component/Supplier/InputNewSupplier.vue');
const MasterDataFoodIngredients = () =>
  import('./components/Master/MasterDataFoodIngredients.vue');
const MasterDataBank = () => import('./components/Master/MasterDataBank.vue');
const MasterDataPartnerInstitute = () =>
  import('./components/Master/MasterDataPartnerInstitute.vue');
const MasterDataLaboratorium = () =>
  import('./components/Master/MasterDataLaboratorium.vue');
const MasterDataFoodMenu = () =>
  import('./components/Master/MasterDataFoodMenu.vue');
const MasterDataCeiling = () =>
  import('./components/Master/MasterDataCeiling.vue');

// ibs & inpatient
const OperationService = () => import('./components/IBS/OperationService.vue');
const OperationSchedule = () =>
  import('./components/IBS/OperationSchedule.vue');
const DelayedOperation = () => import('./components/IBS/DelayedOperation.vue');
const InpatientRegistration = () =>
  import(
    /* webpackChunkName: "chunk-register-inpatient-dashboard" */ /* webpackPrefetch: true */ './components/Inpatient/InpatientRegistration.vue'
  );
const BedMonitoring = () => import('./components/Inpatient/BedMonitoring.vue');
// inventory
const ItemReceipt = () => import('./components/Inventory/ItemReceipt.vue');
const ItemDemand = () => import('./components/Inventory/ItemDemand.vue');
// const ItemOffer = ()=>import ('./components/Inventory/ItemOffer.vue');
const ItemMutation = () => import('./components/Inventory/ItemMutation.vue');
const StockInfo = () => import('./components/Inventory/StockInfo.vue');
const StockOpname = () => import('./components/Inventory/StockOpname.vue');
const ItemOrder = () => import('./components/Inventory/ItemOrder.vue');
const ItemLoaning = () => import('./components/Inventory/ItemLoaning.vue');
const GoodsProcurementPlan = () =>
  import('./components/Inventory/GoodsProcurementPlan.vue');
const PharmacyProcurementPlan = () =>
  import('./components/Inventory/PharmacyProcurementPlan.vue');
const ItemUsage = () => import('./components/Inventory/ItemUsage.vue');
// nutrition
const IngredientUsage = () =>
  import('./components/Nutrition/IngredientUsage.vue');
const OrderPatientFood = () =>
  import('./components/Nutrition/OrderPatientFood.vue');
const RecapFoodOrder = () =>
  import('./components/Nutrition/RecapFoodOrder.vue');
const DetailRecap = () => import('./components/Nutrition/DetailRecap.vue');
const NutritionConsultation = () =>
  import('./components/Nutrition/NutritionConsultation.vue');
// Reporting
const DashboardManagement = () =>
  import('./components/Reporting/DashboardManagement.vue');
const DashboardManagementMenu = () =>
  import('./components/Reporting/Component/DashboardManagementMenu.vue');
const ControlCostPatient = () =>
  import('./components/Reporting/Component/ControlCostPatient.vue');
const DashboardQueueMonitoring = () =>
  import(
    './components/Reporting/Component/QueueMonitoring/DashboardQueueMonitoring.vue'
  );
const MjknAchievementGraph = () =>
  import(
    './components/Reporting/Component/MjknAchievement/MjknAchievementGraph.vue'
  );

const MjknReport = () => import('./components/Reporting/MjknReport.vue');
const SITBDashboard = () => import('./components/Reporting/SITBDashboard.vue');
const PharmacyReporting = () =>
  import('./components/Reporting/PharmacyReporting.vue');
const ExpiredDrugsGraph = () =>
  import('./components/Reporting/Component/ExpiredDrugsGraph.vue');
const DailyCensusReporting = () =>
  import('./components/Reporting/DailyCensusReporting.vue');
const TBCReport = () => import('./components/Reporting/TBCScreeningReport.vue');
const LaboratoryMonthlyReport = () =>
  import('./components/Reporting/LaboratoryMonthlyReport.vue');
const NonCommunicableDiseaseReport = () =>
  import('./components/Reporting/NonCommunicableDiseaseReport.vue');
const ServiceTimeReport = () =>
  import('./components/Reporting/ServiceTimeReport.vue');
const RLRSReport = () =>
  import('./components/Reporting/RecapHospitalReport.vue');
// const DoctorCompliance = ()=>import ('./components/Reporting/Component/DoctorCompliance.vue');
const ServiceQualityReport = () =>
  import('./components/Reporting/ServiceQualityReport.vue');
const PharmacyServiceQuality = () =>
  import('./components/Reporting/Component/PharmacyServiceQuality.vue');
const NonRacikQualityService = () =>
  import('./components/Reporting/Component/NonRacikQualityService.vue');
const RacikQualityService = () =>
  import('./components/Reporting/Component/RacikQualityService.vue');
const VisitReporting = () =>
  import('./components/Reporting/VisitReporting.vue');
const DoctorComplianceNational = () =>
  import('./components/Reporting/Component/DoctorComplianceNational.vue');
const DoctorComplianceHospital = () =>
  import('./components/Reporting/Component/DoctorComplianceHospital.vue');
// Billing
const Billing = () => import('./components/Finance/Billing.vue');
const Accounting = () => import('./components/Finance/Accounting.vue');
const Financing = () => import('./components/Finance/Financing.vue');
const CashTransfer = () =>
  import('./components/Finance/CashTransfer/CashTransfer.vue');
const PatientBilling = () => import('./components/Finance/PatientBilling.vue');
const CashVerification = () =>
  import('./components/Finance/CashVerification.vue');
const BillingReport = () =>
  import('./components/Finance/Report/BillingReport.vue');
const VerificationReport = () =>
  import('./components/Finance/Report/VerificationReport.vue');
const IncomeReport = () =>
  import('./components/Finance/Report/IncomeReport.vue');
const ReceivableRecap = () =>
  import('./components/Finance/Report/ReceivableReport.vue');
const WadiahHistoryReport = () =>
  import('./components/Finance/Report/WadiahHistoryReport.vue');
const HealthInsuranceReport = () =>
  import('./components/Finance/Report/HealthInsuranceReport.vue');
const ReceivableHistoryReport = () =>
  import('./components/Finance/Report/ReceivableHistoryReport.vue');
const CashOutFlow = () => import('./components/Finance/Cashout/CashOut.vue');
const WarehouseReport = () =>
  import('./components/Finance/WarehouseReport.vue');

// Akuntansi
const JournalList = () => import('./components/Finance/JournalList.vue');
const BackdateJournalList = () =>
  import('./components/Finance/BackdateJournalList.vue');
const Accountlist = () => import('./components/Finance/AccountList.vue');
const CloseBook = () => import('./components/Finance/CloseBook.vue');
const HistoricalCloseBook = () =>
  import('./components/Finance/HistoricalCloseBook.vue');
const TrialBalance = () => import('./components/Finance/TrialBalance.vue');
const SupplierDebtReport = () =>
  import('./components/Finance/SupplierDebtReport.vue');
const SupplierPayment = () =>
  import('./components/Finance/SupplierPayment.vue');
const CashIn = () => import('./components/Finance/CashIn/CashInDashboard.vue');
const SubledgerReport = () =>
  import('./components/Finance/Subledger/SubledgerReport.vue');
const ReceivableBPJS = () => import('./components/Finance/ReceivableBPJS.vue');
const LedgerReport = () => import('./components/Finance/Ledger.vue');
const ReceivablePatient = () =>
  import('./components/Finance/ReceivablePatient.vue');
const ReceivableOthers = () =>
  import('./components/Finance/ReceivableOthers.vue');
const PaymentApproval = () =>
  import('./components/Finance/PaymentApproval.vue');

// Settings
// const Settings = ()=>import ('./components/Finance/Settings.vue');
// const CashAndBankSettings = ()=>import ('./components/Finance/Settings/CashAndBankSettings.vue');
// const DebtSettings = ()=>import ('./components/Finance/Settings/DebtSettings.vue');
// Settings
const Settings = () => import('./components/Finance/Settings.vue');

const Donations = () => import('./components/Finance/Donation/Donations.vue');
const Faq = () => import('./components/Finance/Faq/Faq.vue');
// const ZisAcceptance = () =>
//   import('./components/Finance/Donation/ZisAcceptance.vue');
const ZakatsReceipt = () =>
  import('./components/Finance/Donation/Zakat/ZakatReceipt.vue');
const DkReceipt = () => import('./components/Finance/Donation/DkReceipt.vue');
const DkDistribution = () =>
  import('./components/Finance/Donation/DkDistribution.vue');
const ZakatsDistribution = () =>
  import('./components/Finance/Donation/Zakat/ZakatDistribution.vue');

const CashAndBankSettings = () =>
  import('./components/Finance/Settings/CashAndBankSettings.vue');
const DebtSettings = () =>
  import('./components/Finance/Settings/DebtSettings.vue');
// const ReceivableSettings = () =>
//   import('./components/Finance/Settings/ReceivableSettings.vue');

// CaseMix
const BPJSClaimMinutes = () =>
  import('./components/Casemix/BPJSClaimMinutes.vue');
const BPJSArchiveClaim = () =>
  import('./components/Casemix/BPJSArchiveClaim.vue');

// special-service
const FuneralCorpse = () =>
  import('./components/SpecialService/FuneralCorpse.vue');
const Ambulance = () => import('./components/SpecialService/Ambulance.vue');

// self check-in
const SelfCheckIn = () => import('./components/Registration/SelfCheckIn.vue');
const RegistrationQueueDisplay = () =>
  import(
    './components/Registration/RegistrationQueue/RegistrationQueueDisplay.vue'
  );
const RegistrationQueuePatient = () =>
  import(
    './components/Registration/RegistrationQueue/RegistrationQueuePatient.vue'
  );
const RegistrationQueueStaff = () =>
  import(
    './components/Registration/RegistrationQueue/RegistrationQueueStaff.vue'
  );

Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/antrean-pendaftaran-display',
      name: 'RegistrationQueueDisplay',
      component: RegistrationQueueDisplay,
    },
    {
      path: '/antrean-pendaftaran-pasien',
      name: 'RegistrationQueuePatient',
      component: RegistrationQueuePatient,
    },
    {
      path: '/check-in',
      name: 'SelfCheckIn',
      component: SelfCheckIn,
    },
    {
      path: '/antrean-pendaftaran-staff',
      name: 'RegistrationQueueStaff',
      component: RegistrationQueueStaff,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/farmasi/:poly/printantrian',
      name: 'DrugQueueNumber',
      component: DrugQueueNumber,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/farmasi/:poly/antrian',
      name: 'DrugQueue',
      component: DrugQueue,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/:pelayanan/:poly/antrian/:doctor',
      name: 'QueueDashboard',
      component: QueueDashboard,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
    },
    {
      path: '/',
      component: DashboardPage,
      props: true,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'DashboardComponent',
          component: DashboardComponent,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // masterdata
        {
          path: '/masterdata/drugs',
          name: 'MasterDataDrugs',
          component: MasterDataDrugs,
          props: true,
          meta: {
            code: '1.1',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/drugs/drugs-baru',
          name: 'InputDataDrugs',
          component: InputDataDrugs,
          props: true,
          meta: {
            code: '1.1',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/goods',
          name: 'MasterDataGoods',
          component: MasterDataGoods,
          props: true,
          meta: {
            code: '1.2',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/goods/goods-baru',
          name: 'InputDataGoods',
          component: InputDataGoods,
          props: true,
          meta: {
            code: '1.2',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/staff',
          name: 'MasterDataStaff',
          component: MasterDataStaff,
          props: true,
          meta: {
            code: '1.3',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/staff/staff-baru',
          name: 'InputDataStaff',
          component: InputDataStaff,
          props: true,
          meta: {
            code: '1.3',
            requiresAuth: true,
          },
        },

        {
          path: '/masterdata/diagnosa',
          name: 'MasterDataDiagnosa',
          component: MasterDataDiagnosa,
          props: true,
          meta: {
            code: '1.4',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/diagnosa/diagnosa-baru',
          name: 'InputDataDiagnosa',
          component: InputDataDiagnosa,
          props: true,
          meta: {
            code: '1.4',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/prosedur',
          name: 'MasterDataProcedure',
          component: MasterDataProcedure,
          props: true,
          meta: {
            code: '1.5',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/prosedur/prosedur-baru',
          name: 'InputDataProcedure',
          component: InputDataProcedure,
          props: true,
          meta: {
            code: '1.5',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/unit',
          name: 'MasterDataUnit',
          component: MasterDataUnit,
          props: true,
          meta: {
            code: '1.6',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/unit/unit-baru',
          name: 'InputDataUnit',
          component: InputDataUnit,
          props: true,
          meta: {
            code: '1.6',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/layanan',
          name: 'MasterDataIntervention',
          component: MasterDataIntervention,
          props: true,
          meta: {
            code: '1.7',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/layanan/layanan-baru',
          name: 'InputDataIntervention',
          component: InputDataIntervention,
          props: true,
          meta: {
            code: '1.7',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/supplier',
          name: 'MasterDataSupplier',
          component: MasterDataSupplier,
          props: true,
          meta: {
            code: '1.8',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/supplier/supplier-baru',
          name: 'InputDataSupplier',
          component: InputDataSupplier,
          props: true,
          meta: {
            code: '1.8',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/bahan-makanan',
          name: 'MasterDataFoodIngredients',
          component: MasterDataFoodIngredients,
          props: true,
          meta: {
            code: '1.9',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/bed',
          name: 'MasterDataBed',
          component: MasterDataBed,
          props: true,
          meta: {
            code: '1.10',
            requiresAuth: true,
          },
        },

        {
          path: '/masterdata/room',
          name: 'MasterDataRoom',
          component: MasterDataRoom,
          props: true,
          meta: {
            code: '1.11',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/ward',
          name: 'MasterDataWard',
          component: MasterDataWard,
          props: true,
          meta: {
            code: '1.12',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/bank',
          name: 'MasterDataBank',
          component: MasterDataBank,
          props: true,
          meta: {
            code: '1.13',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/instansi-rekanan',
          name: 'MasterDataPartnerInstitute',
          component: MasterDataPartnerInstitute,
          props: true,
          meta: {
            code: '1.14',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/laboratorium',
          name: 'MasterDataLaboratorium',
          component: MasterDataLaboratorium,
          props: true,
          meta: {
            code: '1.15',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/menu-makanan',
          name: 'MasterDataFoodMenu',
          component: MasterDataFoodMenu,
          props: true,
          meta: {
            code: '1.16',
            requiresAuth: true,
          },
        },
        {
          path: '/masterdata/biaya-plafon',
          name: 'MasterDataCeiling',
          component: MasterDataCeiling,
          props: true,
          meta: {
            code: '1.17',
            requiresAuth: true,
          },
        },
        // EMR
        {
          path: '/emr',
          name: 'EmrPage',
          component: EmrPage,
          props: true,
          meta: {
            code: '2.1',
            requiresAuth: true,
          },
        },
        {
          path: '/emr/pengajuan-rubah-data',
          name: 'EmrDashboardDataChange',
          component: EmrDashboardDataChange,
          props: true,
          meta: {
            code: '2.2',
            requiresAuth: true,
          },
        },
        {
          path: '/emr/patient/:id',
          name: 'EmrDashboardPage',
          component: EmrDashboardPage,
          props: true,
          meta: {
            code: '2.1',
            requiresAuth: true,
          },
        },
        // Pendaftaran
        {
          path: '/pendaftaran/pasien-baru',
          name: 'NewPatient',
          component: NewPatient,
          props: true,
          meta: {
            code: '3.1',
            requireSocket: true,
            socketModule: 'nurse-station',
            requiresAuth: true,
          },
        },
        {
          path: '/pendaftaran/tracer-pasien-baru',
          name: 'Tracer',
          component: Tracer,
          props: true,
          meta: {
            code: '3.1',
            requireSocket: true,
            socketModule: '',
            requiresAuth: true,
          },
        },
        {
          path: '/pendaftaran/igd',
          name: 'NewPatientIGD',
          component: NewPatientIGD,
          props: true,
          meta: {
            code: '3.2',
            requiresAuth: true,
          },
        },
        {
          path: '/pendaftaran/rawat-inap',
          name: 'InpatientRegistration',
          component: InpatientRegistration,
          props: true,
          meta: {
            code: '3.3',
            requiresAuth: true,
          },
        },
        {
          path: '/pendaftaran/ketersediaan-bed',
          name: 'BedMonitoring',
          component: BedMonitoring,
          props: true,
          meta: {
            code: '3.4',
            requiresAuth: true,
          },
        },
        {
          path: '/pendaftaran/jadwal-dokter',
          name: 'DoctorSchedule',
          component: DoctorSchedule,
          props: true,
          meta: {
            code: '3.5',
            requiresAuth: true,
          },
        },
        {
          path: '/pendaftaran/jadwal-dokter/pengaturan',
          name: 'SettingDashboard',
          component: SettingDashboard,
          props: true,
          meta: {
            code: '3.5',
            requiresAuth: true,
          },
          children: [
            {
              path: '',
              name: 'MainSetting',
              component: MainSetting,
              props: true,
              meta: {
                code: '3.5',
                requiresAuth: true,
              },
            },
            {
              path: 'ubah-kuota',
              name: 'ChangeKuota',
              component: ChangeQuota,
              props: true,
              meta: {
                code: '3.5',
                requiresAuth: true,
              },
            },
            {
              path: 'ubah-jadwal',
              name: 'ChangeSchedule',
              component: ChangeSchedule,
              props: true,
              meta: {
                code: '3.5',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/pendaftaran/update-status-bed',
          name: 'BedStatusUpdate',
          component: BedStatusUpdate,
          props: true,
          meta: {
            code: '3.6',
            requiresAuth: true,
          },
        },
        // Pelayanan
        {
          path: '/pelayanan/rawat-inap',
          name: 'InpatientCare',
          component: InpatientCare,
          props: true,
          meta: {
            code: '4.1',
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/poli-igd',
          name: 'EmergencyIgd',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.2',
            polyId: 4,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/hemodialisa',
          name: 'Hemodialysis',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.4',
            polyId: 9,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/rehab-medik',
          name: 'MedicalRehabilitation',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.6',
            polyId: 85,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/klinik-kebidanan',
          name: 'MidwifeClinic',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.7',
            polyId: 3,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/klinik-mata',
          name: 'EyeClinic',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.8',
            polyId: 5,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/klinik-bedah-mulut',
          name: 'DentalSurgery',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.11',
            polyId: 36,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/klinik-gigi-dan-mulut',
          name: 'DentalClinic',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.13',
            polyId: 2,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/klinik-orthodentis',
          name: 'Orthodentis',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.14',
            polyId: 35,
            requiresAuth: true,
          },
        },

        {
          path: '/pelayanan/klinik-psikologi',
          name: 'PsychologyClinic',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '4.15',
            polyId: 6,
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/nurse-station',
          name: 'NurseStation',
          component: NurseStation,
          props: true,
          meta: {
            code: '4.27',
            requireSocket: true,
            socketModule: 'nurse-station',
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/unit-vk',
          name: 'VKClinic',
          component: VKClinic,
          props: true,
          meta: {
            code: '4.28',
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan/:poly',
          name: 'GeneralOutpatientCare',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: [
              {
                path: 'klinik-bedah-umum',
                code: '4.9',
                polyId: 14,
              },
              {
                path: 'klinik-bedah-anak',
                code: '4.10',
                polyId: 19,
              },
              {
                path: 'klinik-anak',
                code: '4.12',
                polyId: 10,
              },
              { path: 'klinik-jiwa', code: '4.16', polyId: 15 },
              {
                path: 'klinik-penyakit-dalam',
                code: '4.17',
                polyId: 1,
              },
              {
                path: 'klinik-syaraf',
                code: '4.18',
                polyId: 11,
              },
              {
                path: 'klinik-jantung',
                code: '4.19',
                polyId: 12,
              },
              {
                path: 'klinik-tht',
                code: '4.20',
                polyId: 13,
              },
              {
                path: 'klinik-orthopedi',
                code: '4.21',
                polyId: 17,
              },
              {
                path: 'klinik-paru',
                code: '4.22',
                polyId: 18,
              },
              {
                path: 'klinik-mcu',
                code: '4.23',
                polyId: 65,
              },
              {
                path: 'klinik-geriatri',
                code: '4.24',
                polyId: 87,
              },
              {
                path: 'klinik-vaksinasi',
                code: '4.25',
                polyId: 88,
              },
              {
                path: 'klinik-gizi',
                code: '4.26',
                polyId: 80,
              },
              {
                path: 'klinik-tb-dots',
                code: '4.29',
                polyId: 152,
              },
              {
                path: 'klinik-anestesi',
                code: '4.30',
                polyId: 153,
              },
              {
                path: 'klinik-urologi',
                code: '4.31',
                polyId: 169,
              },
              {
                path: 'klinik-bayi-baru-lahir',
                code: '4.32',
                polyId: 170,
              },
              {
                path: 'klinik-berhenti-merokok',
                code: '4.33',
                polyId: 172,
              },
              {
                path: 'klinik-keluarga-sakinah',
                code: '4.34',
                polyId: 171,
              },
              {
                path: 'klinik-bedah-saraf',
                code: '4.35',
                polyId: 173,
              },
            ],
            requiresAuth: true,
          },
        },
        // IBS
        {
          path: '/ibs/pelayanan-operasi',
          name: 'OperationService',
          component: OperationService,
          props: true,
          meta: {
            code: '5.1',
            requiresAuth: true,
          },
        },
        {
          path: '/ibs/jadwal-operasi',
          name: 'OperationSchedule',
          component: OperationSchedule,
          props: true,
          meta: {
            code: '5.2',
            requiresAuth: true,
          },
        },
        {
          path: '/ibs/penundaan-operasi',
          name: 'DelayedOperation',
          component: DelayedOperation,
          props: true,
          meta: {
            code: '5.2',
            requiresAuth: true,
          },
        },
        // Farmasi
        {
          path: '/farmasi/:poly',
          name: 'PharmacyMainMenu',
          component: PharmacyMainMenu,
          props: true,
          meta: {
            code: [
              { path: 'rawat-inap', code: '6.1' },
              { path: 'rawat-jalan', code: '6.2' },
              { path: 'igd', code: '6.3' },
              { path: 'ibs', code: '6.4' },
              { path: 'hd', code: '6.5' },
            ],
            requiresAuth: true,
          },
        },
        {
          path: '/farmasi/:poly/penyerahan',
          name: 'DrugHandover',
          component: DrugHandover,
          props: true,
          meta: {
            code: [
              { path: 'rawat-inap', code: '6.1' },
              { path: 'rawat-jalan', code: '6.2' },
              { path: 'igd', code: '6.3' },
              { path: 'ibs', code: '6.4' },
              { path: 'hd', code: '6.5' },
            ],
            requiresAuth: true,
          },
        },
        {
          path: '/farmasi/:poly/retur',
          name: 'DrugReturns',
          component: DrugReturns,
          props: true,
          meta: {
            code: [
              { path: 'rawat-inap', code: '6.1' },
              { path: 'rawat-jalan', code: '6.2' },
              { path: 'igd', code: '6.3' },
              { path: 'ibs', code: '6.4' },
              { path: 'hd', code: '6.5' },
            ],
            requiresAuth: true,
          },
        },
        {
          path: '/farmasi/:poly/pembuatan',
          name: 'DrugsCreate',
          component: DrugsCreate,
          props: true,
          meta: {
            code: [
              { path: 'rawat-inap', code: '6.1' },
              { path: 'rawat-jalan', code: '6.2' },
              { path: 'igd', code: '6.3' },
              { path: 'ibs', code: '6.4' },
              { path: 'hd', code: '6.5' },
            ],
            requiresAuth: true,
          },
        },
        {
          path: '/farmasi/:poly/permintaan',
          name: 'DrugsToolsRequest',
          component: DrugsToolsRequest,
          props: true,
          meta: {
            code: [
              { path: 'rawat-inap', code: '6.1' },
              { path: 'rawat-jalan', code: '6.2' },
              { path: 'igd', code: '6.3' },
              { path: 'ibs', code: '6.4' },
              { path: 'hd', code: '6.5' },
            ],
            requiresAuth: true,
          },
        },
        {
          path: '/farmasi/:poly/penjualan-obat-bebas',
          name: 'DrugsSelling',
          component: DrugsSelling,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/farmasi/:poly/persiapan-obat',
          name: 'DrugsReview',
          component: DrugsReview,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/farmasi/:poly/persiapan-obat-cpo',
          name: 'DrugsReviewCPO',
          component: DrugsReviewCPO,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // Reporting
        {
          path: '/reporting/reporting-farmasi',
          name: 'PharmacyReporting',
          component: PharmacyReporting,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/reporting-farmasi/kepatuhan-peresepan-dokter-rumah-sakit',
          name: 'DoctorComplianceHospital',
          component: DoctorComplianceHospital,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/reporting-farmasi/angka-obat-kadaluarsa',
          name: 'ExpiredDrugsGraph',
          component: ExpiredDrugsGraph,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/reporting-farmasi/kepatuhan-peresepan-dokter-nasional',
          name: 'DoctorComplianceNational',
          component: DoctorComplianceNational,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/reporting-kunjungan-pasien',
          name: 'VisitReporting',
          component: VisitReporting,
          props: true,
          meta: {
            code: '7.2',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/laporan-sensus-harian',
          name: 'DailyCensusReporting',
          component: DailyCensusReporting,
          props: true,
          meta: {
            code: '7.3',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/reporting-sitb',
          name: 'SITBDashboard',
          component: SITBDashboard,
          props: true,
          meta: {
            code: '7.4',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/response-pelayanan-rj',
          name: 'ServiceTimeReport',
          component: ServiceTimeReport,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/laporan-bulanan-laboratorium',
          name: 'LaboratoryMonthlyReport',
          component: LaboratoryMonthlyReport,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/reporting-tb',
          name: 'TBCReport',
          component: TBCReport,
          props: true,
          meta: {
            code: '7.5',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/penyakit-tidak-menular',
          name: 'NonCommunicableDiseaseReport',
          component: NonCommunicableDiseaseReport,
          props: true,
          meta: {
            code: '7.6',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/reporting-recap-hospital',
          name: 'RLRSReport',
          component: RLRSReport,
          props: true,
          meta: {
            code: '7.7',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/data-antrian-online',
          name: 'MjknReport',
          component: MjknReport,
          props: true,
          meta: {
            code: '7.7',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/dashboard-management',
          name: 'DashboardManagement',
          component: DashboardManagement,
          props: true,
          meta: {
            code: '7.7',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/dashboard-management/monitoring-antrean-online-BPJS',
          name: 'DashboardQueueMonitoring',
          component: DashboardQueueMonitoring,
          props: true,
          meta: {
            code: '7.7',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/dashboard-management/grafik-capaian-MJKN',
          name: 'MjknAchievementGraph',
          component: MjknAchievementGraph,
          props: true,
          meta: {
            code: '7.7',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/dashboard-management/:page',
          name: 'DashboardManagementMenu',
          component: DashboardManagementMenu,
          props: true,
          meta: {
            code: '7.7',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/dashboard-management/:control/:overcost',
          name: 'ControlCostPatient',
          component: ControlCostPatient,
          props: true,
          meta: {
            code: '7.7',
            requiresAuth: true,
          },
        },

        {
          path: '/reporting/mutu-pelayanan/mutu-pelayanan-farmasi/nonracik',
          name: 'ServiceQualityReport',
          component: NonRacikQualityService,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/mutu-pelayanan/mutu-pelayanan-farmasi/racik',
          name: 'ServiceQualityReport',
          component: RacikQualityService,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/mutu-pelayanan',
          name: 'ServiceQualityReport',
          component: ServiceQualityReport,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/mutu-pelayanan/mutu-pelayanan-farmasi',
          name: 'ServiceQualityReport',
          component: PharmacyServiceQuality,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/mutu-pelayanan/mutu-pelayanan-farmasi/nonracik',
          name: 'ServiceQualityReport',
          component: NonRacikQualityService,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        {
          path: '/reporting/mutu-pelayanan/mutu-pelayanan-farmasi/racik',
          name: 'ServiceQualityReport',
          component: RacikQualityService,
          props: true,
          meta: {
            code: '7.1',
            requiresAuth: true,
          },
        },
        // Penunjang
        {
          path: '/radiologi',
          name: 'RadiologyComponent',
          component: RadiologyComponent,
          props: true,
          meta: {
            code: '8.1',
            requiresAuth: true,
          },
        },
        {
          path: '/laboratorium/klinik',
          name: 'LabComponent',
          component: LabComponent,
          props: true,
          meta: {
            code: '9.1',
            requiresAuth: true,
          },
        },
        {
          path: '/laboratorium/patologi',
          name: 'PathologyComponent',
          component: PathologyComponent,
          props: true,
          meta: {
            code: '9.2',
            requiresAuth: true,
          },
        },
        {
          path: '/laboratorium/transfusi-darah',
          name: 'BloodRequestComponent',
          component: BloodRequestComponent,
          props: true,
          meta: {
            code: '9.3',
            requiresAuth: true,
          },
        },
        // Billing
        {
          path: '/pembayaran',
          name: 'BillingPayment',
          component: Billing,
          props: true,
          meta: {
            code: '10.1',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/billing',
          name: 'Billing',
          component: Billing,
          props: true,
          meta: {
            code: '10.1',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/billing/TagihanPasien',
          name: 'PatientBilling',
          component: PatientBilling,
          props: true,
          meta: {
            code: '10.1',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/billing/PembayaranTunai',
          name: 'CashPayment',
          component: PatientBilling,
          props: true,
          meta: {
            code: '10.1',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/billing/VerifikasiSetorKas',
          name: 'CashVerification',
          component: CashVerification,
          props: true,
          meta: {
            code: '10.1',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/billing/LaporanBilling',
          name: 'BillingReport',
          component: BillingReport,
          props: true,
          meta: {
            code: '10.1',
            requiresAuth: true,
          },
          children: [
            {
              path: 'LaporanVerifikasi',
              name: 'VerificationReport',
              component: VerificationReport,
              props: true,
              meta: {
                code: '10.1',
                requiresAuth: true,
              },
            },
            {
              path: 'LaporanPendapatan',
              name: 'IncomeReport',
              component: IncomeReport,
              props: true,
              meta: {
                code: '10.1',
                requiresAuth: true,
              },
            },
            {
              path: 'RekapitulasiPiutang',
              name: 'ReceivableRecap',
              component: ReceivableRecap,
              props: true,
              meta: {
                code: '10.1',
                requiresAuth: true,
              },
            },
            {
              path: 'LaporanHistorisWadiah',
              name: 'WadiahHistoryReport',
              component: WadiahHistoryReport,
              props: true,
              meta: {
                code: '10.1',
                requiresAuth: true,
              },
            },
            {
              path: 'LaporanPerTipePenjamin',
              name: 'HealthInsuranceReport',
              component: HealthInsuranceReport,
              props: true,
              meta: {
                code: '10.1',
                requiresAuth: true,
              },
            },
            {
              path: 'LaporanHistorisPiutang',
              name: 'ReceivableHistoryReport',
              component: ReceivableHistoryReport,
              props: true,
              meta: {
                code: '10.1',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/keuangan/akuntansi',
          name: 'Accounting',
          component: Accounting,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/daftar-akun',
          name: 'AccountList',
          component: Accountlist,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/tutup-buku',
          name: 'CloseBook',
          component: CloseBook,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/riwayat-tutup-buku',
          name: 'HistoricalCloseBook',
          component: HistoricalCloseBook,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/neraca-saldo',
          name: 'TrialBalance',
          component: TrialBalance,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/buku-besar-pembantu',
          name: 'SubledgerReport',
          component: SubledgerReport,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/buku-besar',
          name: 'LedgerReport',
          component: LedgerReport,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/jurnal/daftar-jurnal',
          name: 'JournalList',
          component: JournalList,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/jurnal/daftar-persetujuan-jurnal',
          name: 'BackdateJournalList',
          component: BackdateJournalList,
          props: true,
          meta: {
            code: '10.2',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan',
          name: 'Financing',
          component: Financing,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        // {
        //   path: '/keuangan/piutang/piutang-pasien',
        //   name: 'ReceivablePatient',
        //   component: ReceivablePatient,
        //   props: true,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        {
          path: '/keuangan/akuntansi/laporan-utang-suplier',
          name: 'SupplierDebtReport',
          component: SupplierDebtReport,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/pembayaran-suplier',
          name: 'SupplierPayment',
          component: SupplierPayment,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/kas-dan-bank/kas-keluar',
          name: 'CashOutFlow',
          component: CashOutFlow,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/persetujuan-pembayaran',
          name: 'PaymentApproval',
          component: PaymentApproval,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi/laporan-piutang-bpjs',
          name: 'ReceivableBPJS',
          component: ReceivableBPJS,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/transfer-kas',
          name: 'CashTransfer',
          component: CashTransfer,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/akuntansi',
          name: 'Accounting',
          component: Accounting,
        },
        {
          path: '/keuangan/kas-dan-bank/kas-masuk',
          name: 'CashIn',
          component: CashIn,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/piutang/piutang-pasien',
          name: 'ReceivablePatient',
          component: ReceivablePatient,
          props: true,
          meta: {
            code: '10.3',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/settings',
          name: 'Settings',
          component: Settings,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/settings/kas-dan-bank',
          name: 'Pengaturan Modul Kas dan Bnk',
          component: CashAndBankSettings,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/settings/pengaturan-modul-utang',
          name: 'Pengaturan Modul Utang',
          component: DebtSettings,
          props: true,
          meta: {
            code: '10.5',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/donasi',
          name: 'Donations',
          component: Donations,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/donasi/penerimaan-zakat',
          name: 'Penerimaan Zakat',
          component: ZakatsReceipt,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/donasi/penyaluran-zakat',
          name: 'Penyaluran Zakat',
          component: ZakatsDistribution,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },

        {
          path: '/keuangan/donasi/Penggunaan-dk',
          name: 'Penggunaan DK',
          component: DkDistribution,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },

        {
          path: '/keuangan/donasi/penerimaan-dk',
          name: 'Penerimaan DK',
          component: DkReceipt,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },

        {
          path: '/keuangan/pusat-bantuan',
          name: 'Faq',
          component: Faq,
          props: true,
          meta: {
            code: '10.4',
            requiresAuth: true,
          },
        },

        {
          path: '/keuangan/settings/pengaturan-modul-piutang',
          name: 'Pengaturan Modul Piutang',
          component: ReceivableOthers,
          props: true,
          meta: {
            code: '10.6',
            requiresAuth: true,
          },
        },
        {
          path: '/keuangan/persediaan',
          name: 'Laporan Persediaan',
          component: WarehouseReport,
          props: true,
          meta: {
            code: '10.7',
            requiresAuth: true,
          },
        },
        // Inventory
        {
          path: '/inventaris/pemesanan',
          name: 'ItemOrder',
          component: ItemOrder,
          props: true,
          meta: {
            code: '11.1',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/penerimaan',
          name: 'ItemReceipt',
          component: ItemReceipt,
          props: true,
          meta: {
            code: '11.2',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/stok',
          name: 'StockInfo',
          component: StockInfo,
          props: true,
          meta: {
            code: '11.3',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/permintaan',
          name: 'ItemDemand',
          component: ItemDemand,
          props: true,
          meta: {
            code: '11.4',
            requiresAuth: true,
          },
        },
        // {
        //   path: '/inventaris/pengiriman',
        //   name: 'ItemOffer',
        //   component: ItemOffer,
        //   props: true,
        //   meta: {
        //     code: '11.5',
        //     requiresAuth: true,
        //   },
        // },
        {
          path: '/inventaris/mutasi',
          name: 'ItemMutation',
          component: ItemMutation,
          props: true,
          meta: {
            code: '11.6',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/stok-opname',
          name: 'StockOpname',
          component: StockOpname,
          props: true,
          meta: {
            code: '11.7',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/rencana-pengadaan/rumah-tangga',
          name: 'GoodsProcurementPlan',
          component: GoodsProcurementPlan,
          props: true,
          meta: {
            code: '11.8',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/rencana-pengadaan/farmasi',
          name: 'PharmacyProcurementPlan',
          component: PharmacyProcurementPlan,
          props: true,
          meta: {
            code: '11.9',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/peminjaman',
          name: 'ItemLoaning',
          component: ItemLoaning,
          props: true,
          meta: {
            code: '11.10',
            requiresAuth: true,
          },
        },
        {
          path: '/inventaris/pemakaian-barang-unit',
          name: 'ItemUsage',
          component: ItemUsage,
          props: true,
          meta: {
            code: '11.11',
            requiresAuth: true,
          },
        },
        // Gizi
        {
          path: '/gizi/order-makanan-pasien',
          name: 'OrderPatientFood',
          component: OrderPatientFood,
          props: true,
          meta: {
            code: '12.1',
            requiresAuth: true,
          },
        },
        {
          path: '/gizi/rekap-order-makanan',
          name: 'RecapFoodOrder',
          component: RecapFoodOrder,
          props: true,
          meta: {
            code: '12.2',
            requiresAuth: true,
          },
        },
        {
          path: '/gizi/rekap-order-makanan/rekap-makan-pokok',
          name: 'DetailRecap',
          component: DetailRecap,
          props: true,
          meta: {
            code: '12.2',
            requiresAuth: true,
          },
        },
        {
          path: '/gizi/penggunaan-bahan-gizi',
          name: 'IngredientUsage',
          component: IngredientUsage,
          props: true,
          meta: {
            code: '12.3',
            requiresAuth: true,
          },
        },
        {
          path: '/gizi/konsultasi-gizi-rawat-inap',
          name: 'NutritionConsultation',
          component: NutritionConsultation,
          props: true,
          meta: {
            code: '12.4',
            requiresAuth: true,
          },
        },
        // Case Mix
        {
          path: '/casemix/berita-klaim-bpjs',
          name: 'BPJSClaimMinutes',
          component: BPJSClaimMinutes,
          props: true,
          meta: {
            code: '13.1',
            requiresAuth: true,
          },
        },
        {
          path: '/casemix/pemberkasan-klaim-bpjs',
          name: 'BPJSArchiveClaim',
          component: BPJSArchiveClaim,
          props: true,
          meta: {
            code: '13.2',
            requiresAuth: true,
          },
        },

        // Penunjang Medis
        {
          path: '/penunjang-medis/fisioterapi',
          name: 'FisioTherapy',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '14.1',
            polyId: 7,
            requiresAuth: true,
          },
        },
        {
          path: '/penunjang-medis/terapi-wicara',
          name: 'TalkTherapy',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '14.2',
            polyId: 8,
            requiresAuth: true,
          },
        },
        {
          path: '/penunjang-medis/terapi-okupasi',
          name: 'OccupationTherapy',
          component: GeneralOutpatientCare,
          props: true,
          meta: {
            code: '14.3',
            polyId: 86,
            requiresAuth: true,
          },
        },
        // pelayanan khusus
        {
          path: '/pelayanan-khusus/pemulasaraan-jenazah',
          name: 'FuneralCorpse',
          component: FuneralCorpse,
          props: true,
          meta: {
            code: '15.1',
            requiresAuth: true,
          },
        },
        {
          path: '/pelayanan-khusus/ambulance',
          name: 'Ambulance',
          component: Ambulance,
          props: true,
          meta: {
            code: '15.2',
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isExpired = moment() > moment(store.getters.tokenTime * 1000);
  if (to.matched.some((value) => value.meta.requiresAuth)) {
    if (
      localStorage.getItem('token') == null ||
      (isExpired && to.path === '/')
    ) {
      next({
        path: '/login',
      });
    } else {
      try {
        if (
          to.meta.code &&
          store.getters.userLoggedIn.role.primary !== 'administrator'
        ) {
          const { access } = store.getters.userLoggedIn;
          if (Array.isArray(to.meta.code)) {
            const { code } = to.meta.code.find(
              (arr) => arr.path === to.params.poly,
            );
            if (!access.includes(code)) {
              next({
                path: from.path,
              });
              throw Error;
            }
          } else if (!access.includes(to.meta.code)) {
            next({
              path: from.path,
            });
            throw Error;
          }
        }
        if (
          Vue.prototype.$socket.client.connected &&
          to.meta.socketModule !==
            Vue.prototype.$socket.client.io.opts.query.module
        )
          Vue.prototype.$socket.client.close();
        if (!Vue.prototype.$socket.client.connected && to.meta.socketModule) {
          Vue.prototype.$socket.client.io.opts.query = {
            token: localStorage.getItem('token'),
          };
          if (to.meta.requireSocket) {
            Vue.prototype.$socket.client.io.opts.query = {
              ...Vue.prototype.$socket.client.io.opts.query,
              module: to.meta.socketModule,
            };
          }
          Vue.prototype.$socket.client.open();
        }
        next();
      } catch (error) {
        // eslint-ignore-next-line
        console.log('', error);
      }
      next();
    }
  } else if (
    to.path === '/login' &&
    localStorage.getItem('token') != null &&
    !isExpired
  ) {
    next({
      path: from.path,
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
