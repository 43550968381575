import Vue from 'vue';
import Vuex from 'vuex';
import Constant from '@/const';
import hemodialysis from '@/store/modules/hemodialysis';
import eye from '@/store/modules/eye';
import dental from '@/store/modules/dental';
import psychology from '@/store/modules/psychology';
import midwife from '@/store/modules/midwife';
import generalOutpatientCare from '@/store/modules/generalOutpatientCare';
import talkTherapy from '@/store/modules/talkTherapy';
import fisioTherapy from '@/store/modules/fisioTherapy';
import emergencyIgd from '@/store/modules/emergencyIgd';
import action from '@/store/modules/action';
import pharmacy from '@/store/modules/pharmacy';
import consumable from '@/store/modules/consumable';
import eRecipe from '@/store/modules/eRecipe';
import inpatientRegistered from '@/store/modules/inpatientRegistered';
import occupationTherapy from '@/store/modules/occupationTherapy';
import interval from '@/store/modules/interval';
import traumaSurgeryAssessment from '@/store/modules/traumaSurgeryAssessment';
import pregnancyAssessment from '@/store/modules/pregnancyAssessment';
import nurseNeonate from '@/store/modules/nurseNeonate';
import medicalNeonate from '@/store/modules/medicalNeonate';
import pediatricMedicalAssessment from '@/store/modules/pediatricMedicalAssessment';
import initialNursingAssessment from '@/store/modules/initialNursingAssessment';
import childBirthAssessment from '@/store/modules/childBirthAssessment';
import spirometry from '@/store/modules/spirometry';
import echocardiography from '@/store/modules/echocardiography';
import masterDataSupplier from '@/store/modules/masterDataSupplier';
import nutritionOrder from '@/store/modules/nutritionOrder';
import advancedNutritionScreening from './store/modules/advancedNutritionScreening';
import finance from './store/modules/finance/finance';
import journal from './store/modules/finance/journal';
import configuration from '@/store/modules/configuration';
import registrationPatient from '@/store/modules/registrationPatient';
import progressLog from './store/modules/progressLog';
import binaRohaniStore from '@/store/modules/binaRohaniStore';

const axios = require('axios');
const moment = require('moment');
const jwt = require('jsonwebtoken');

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    hemodialysis,
    eye,
    dental,
    psychology,
    midwife,
    generalOutpatientCare,
    talkTherapy,
    fisioTherapy,
    emergencyIgd,
    action,
    consumable,
    eRecipe,
    pharmacy,
    inpatientRegistered,
    occupationTherapy,
    interval,
    traumaSurgeryAssessment,
    pregnancyAssessment,
    nurseNeonate,
    medicalNeonate,
    pediatricMedicalAssessment,
    initialNursingAssessment,
    childBirthAssessment,
    advancedNutritionScreening,
    spirometry,
    echocardiography,
    masterDataSupplier,
    nutritionOrder,
    finance: {
      namespaced: true,
      ...finance,
    },
    journal: {
      namespaced: true,
      ...journal,
    },
    binaRohaniStore: {
      namespaced: true,
      ...binaRohaniStore,
    },
    configuration,
    progressLog,
    registrationPatient: {
      namespaced: true,
      ...registrationPatient,
    },
  },
  state: {
    isMaxDepthAccount: null,
    token: localStorage.getItem('token'),
    tokenRegist: localStorage.getItem('tokenRegist'),
    selectedPatient: null,
    selectedStaff: null,
    bpjsItem: [],
    diagnoseItem: [],
    bpjsCompany: [],
    insuranceCompany: [],
    inpatientBedPick: null,
    newPatientTemp: {
      socialData: null,
      paymentType: null,
      supportService: null,
      polyAndDoctor: null,
      startAdmision: null,
      dataSep: null,
    },
    isMini: false,
    isIgd: false,
    isIgdBpjs: false,
    inputUpdate: false,
    autofillAssesment: false,
    statusAssesment: null,
    triase: null,
    idTriase: null,
    labRequest: null,
    chooseService: null,
    drugSection: {
      drugReconciliation: null,
      drugRecipe: null,
    },
    selectedUnitUser: null,
    isAddSupportHistory: false,
    actionsAndConsumables: {
      actionAndPricing: null,
      consumablesAndGas: null,
      diagnosisAndActions: null,
      interventionResourcesInpatient: [],
      interventionResourcesOutpatient: [],
    },
    emergencyClinic: {
      triaseAssesment: {
        triaseInspect: null,
        triaseLevel: null,
        workingPsychological: null,
      },
      emergencyAssesment: null,
    },
    emr: {
      emrDialogBox: {
        clinic: '',
        clinicId: null,
        doctor: '',
        date: '',
      },
    },
    nurseDiagnose: [],
    nursePlan: [],
    unit: [],
    doctor: [],
    dateRangeDoctor: '',
  },
  getters: {
    isMaxDepthAccount: (state) => state.isMaxDepthAccount,
    diagnoseItem: (state) => state.diagnoseItem,
    bpjsItem: (state) => state.bpjsItem,
    bpjsCompany: (state) => state.bpjsCompany,
    insuranceCompany: (state) => state.insuranceCompany,
    isLoggedIn: (state) => !!state.token,
    token: (state) => state.token,
    tokenRegist: (state) => state.tokenRegist,
    userLoggedIn: (state) => {
      let tempData = null;
      if (state.token !== null) {
        console.log(jwt.decode(state.token).tokenStaff);
        tempData = jwt.decode(state.token).tokenStaff;
      }
      if (state.selectedUnitUser !== null) {
        const userUnit = state.selectedUnitUser;
        tempData = {
          ...tempData,
          role: {
            ...tempData.role,
            is_pharmacy: userUnit.isPharmacy,
            is_wareHouse: userUnit.isWarehouse,
            poly_id: userUnit.id,
            poly_name: userUnit.name,
            poly_number: userUnit.unit_number,
            warehouse_type: userUnit.warehouse_type,
          },
          access: userUnit.access,
        };
      }
      return tempData;
    },
    userUnit: (state) => state.selectedUnitUser,
    isIgd: (state) => state.isIgd,
    isIgdBpjs: (state) => state.isIgdBpjs,
    isMini: (state) => state.isMini,
    inputUpdate: (state) => state.inputUpdate,
    emrAutofill: (state) => state.autofillAssesment,
    statusAssesment: (state) => state.statusAssesment,
    triase: (state) => state.triase,
    idTriase: (state) => state.idTriase,
    newPatientTempSocialData: (state) => state.newPatientTemp.socialData,
    newPatientTempPayment: (state) => state.newPatientTemp.paymentType,
    newPatientTempSupport: (state) => state.newPatientTemp.supportService,
    newPatientTempPolyAndDoctor: (state) => state.newPatientTemp.polyAndDoctor,
    newPatientTempstartAdmision: (state) => state.newPatientTemp.startAdmision,
    newPatientTempDataSEP: (state) => state.newPatientTemp.dataSep,
    inpatientBedPick: (state) => state.inpatientBedPick,
    chooseService: (state) => state.chooseService,
    labRequest: (state) => state.labRequest,
    triaseAssesment: (state) => state.emergencyClinic.triaseAssesment,
    drugReconciliation: (state) => state.drugSection.drugReconciliation,
    drugRecipe: (state) => state.drugSection.drugRecipe,
    actionAndPricing: (state) => state.actionsAndConsumables.actionAndPricing,
    consumablesAndGas: (state) => state.actionsAndConsumables.consumablesAndGas,
    diagnosisAndActions: (state) =>
      state.actionsAndConsumables.diagnosisAndActions,
    getInterventionResourcesInpatient: (state) =>
      state.actionsAndConsumables.interventionResourcesInpatient,
    getInterventionResourcesOutpatient: (state) =>
      state.actionsAndConsumables.interventionResourcesOutpatient,
    selectedPatient: (state) => state.selectedPatient,
    nurseDiagnose: (state) => state.nurseDiagnose,
    nursePlan: (state) => state.nursePlan,
    selectedStaff: (state) => state.selectedStaff,
    drugHistory: (state) => state.generalOutpatientCare.drugHistory,
    emrDialogBox: (state) => state.emr.emrDialogBox,
    isJwtVerified: (state) => {
      try {
        const decoded = jwt.decode(state.token);
        const now = moment().valueOf() / 1000;
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
          throw Error;
        }
        return true;
      } catch {
        return false;
      }
    },
    isJwtVerifiedRegist: (state) => {
      try {
        const decoded = jwt.decode(state.tokenRegist);
        const now = moment().valueOf() / 1000;
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
          throw Error;
        }
        return true;
      } catch {
        return false;
      }
    },
    tokenTime: (state) => {
      try {
        const decoded = jwt.decode(state.token);
        // if (typeof decoded.exp !== 'undefined') {
        //   throw Error;
        // }
        return decoded.exp;
      } catch {
        return false;
      }
    },
    getUnit: (state) => state.unit,
    getDoctor: (state) => state.doctor,
    getDoctorDateRange: (state) => state.dateRangeDoctor,
    getIsAddSupportHistory: (state) => state.isAddSupportHistory,
  },
  mutations: {
    maxDepthAccount(state, payload) {
      state.isMaxDepthAccount = payload;
    },
    setDoctorDateRange(state, payload) {
      state.dateRangeDoctor = payload;
    },
    changeMiniDashboard(state, payload) {
      state.mini = payload;
    },
    changeIgdBpjsState(state, payload) {
      state.isIgdBpjs = payload;
    },
    changeIgdState(state, payload) {
      state.isIgd = payload;
    },
    changeMiniState(state, payload) {
      state.isMini = payload;
    },
    changeInputUpdateState(state, payload) {
      state.inputUpdate = payload;
    },
    changeEmrAutofillState(state, payload) {
      state.autofillAssesment = payload;
    },
    getStatusAssesment(state, payload) {
      if (payload) {
        state.statusAssesment = payload;
      } else {
        state.statusAssesment = null;
      }
    },
    changeTriaseState(state, payload) {
      state.triase = payload;
    },
    changeIdTriaseState(state, payload) {
      state.idTriase = payload;
    },
    saveToken(state, token) {
      console.log(token);
      localStorage.setItem('token', token);
      state.token = token;
    },
    saveTokenRegist(state, token) {
      localStorage.setItem('tokenRegist', token);
      state.tokenRegist = token;
    },
    removeToken(state) {
      state.token = null;
    },
    removeTriase(state) {
      state.triase = null;
    },
    saveInsuranceCompany(state, payload) {
      state.insuranceCompany = payload;
    },
    saveBpjsCompany(state, payload) {
      state.bpjsCompany = payload;
    },
    saveDiagnose(state, payload) {
      state.diagnoseItem = payload;
    },
    saveBPJSItem(state, payload) {
      state.bpjsItem = payload;
    },
    saveBedPick(state, payload) {
      state.inpatientBedPick = payload.data;
    },
    changeChoosenService(state, payload) {
      state.chooseService = payload;
    },
    saveNewPatientTemp(state, payload) {
      // if (state.newPatientTemp.length >= payload.index)
      //   state.newPatientTemp.splice(payload.index - 1, 1, payload.data);
      // if (state.newPatientTemp.length < payload.index)
      //   state.newPatientTemp[payload.index - 1] = payload.data;
      // else state.newPatientTemp.push(payload.data);
      // console.log(state.newPatientTemp);
      state.newPatientTemp[payload.index] = payload.data;
    },
    removeBedPick(state) {
      state.inpatientBedPick = null;
    },
    removeNewPatientTemp(state) {
      state.newPatientTemp = {
        socialData: null,
        paymentType: null,
        supportService: null,
        polyAndDoctor: null,
        startAdmision: null,
        dataSep: null,
      };
      state.chooseService = null;
      state.inpatientBedPick = null;
    },
    saveActionsAndConsumables(state, payload) {
      state.actionsAndConsumables[payload.key] = payload.data;
    },
    removeActionsAndConsumables(state) {
      state.actionsAndConsumables = {
        diagnosisAndActions: null,
        consumablesAndGas: null,
        actionAndPricing: null,
      };
    },
    saveDrugSection(state, payload) {
      state.drugSection[payload.key] = payload.data;
    },
    removeDrugSection(state) {
      state.drugSection = {
        drugReconciliation: null,
        drugRecipe: null,
      };
    },
    saveLabRequest(state, payload) {
      state.labRequest = payload.labs;
    },
    removeLabRequest(state) {
      state.labRequest = null;
    },
    changeSelectedPatient(state, patient) {
      console.log(patient);
      state.selectedPatient = patient;
    },
    changeSelectedStaff(staff) {
      localStorage.setItem('staff_id', staff.id);
    },
    changeEmergencyClinic(state, payload) {
      state.emergencyClinic.triaseAssesment[payload.key] = payload.data;
    },
    removeEmergencyClinic(state) {
      state.emergencyClinic = {
        triaseAssesment: {
          triaseInspect: null,
          triaseLevel: null,
          workingPsychological: null,
        },
        emergencyAssesment: null,
      };
    },
    saveUserUnit(state, payload) {
      console.log(payload);
      state.selectedUnitUser = payload;
    },
    removeUserUnit(state) {
      state.selectedUnitUser = null;
    },
    saveNurseDiagnose(state, payload) {
      state.nurseDiagnose = payload;
    },
    removeNurseDiagnose(state) {
      state.nurseDiagnose = [];
    },
    saveNursePlan(state, payload) {
      state.nursePlan = payload;
    },
    removeNursePlan(state) {
      state.nursePlan = [];
    },
    setUnit(state, payload) {
      state.unit = payload;
    },
    setDoctor(state, payload) {
      state.doctor = payload;
    },
    setIsAddSupportHistory(state, payload) {
      state.isAddSupportHistory = payload;
    },
  },
  actions: {
    async resolveGetInterventionResources(
      { state },
      {
        _,
        isInpatientUnit,
        patientClass,
        selectedPatient,
        typeIs,
        keyword,
        unitType,
      },
    ) {
      let url = isInpatientUnit
        ? `/masterdata/intervention?page=1&itemCount=50&showHidden=false&search_service=${
            keyword || ''
          }&sort=detail.service&classFilter=${patientClass}&filterUnitStock=${
            unitType.id
          }`
        : `/masterdata/intervention/poly/${
            unitType.id
          }?page=1&itemCount=50&showHidden=false&search_service=${
            keyword || ''
          }&sort=detail.service&filterUnitStock=${
            unitType.id
          }&typeFilter=${typeIs}&classFilter=${patientClass}`;

      if (unitType.text.toLowerCase().trim() === 'radiologi') {
        if (
          selectedPatient.meta.patient_status.toLowerCase().trim() === 'igd'
        ) {
          url += `&examination=radiologi&unitFilter=${selectedPatient.refferal_unit},${unitType.id}`;
        } else {
          url += `&examination=radiologi&unitFilter=${unitType.id}`;
        }
      }

      if (unitType.text.toLowerCase().trim() === 'laboratorium') {
        let examtype = 'laboratorium';
        if (unitType.subtype) {
          switch (unitType.subtype) {
            case 'bloodtransfer':
              examtype = 'pelayanan darah';
              break;

            default:
              examtype = 'laboratorium';
              break;
          }
        }
        if (
          selectedPatient.meta.patient_status.toLowerCase().trim() === 'igd'
        ) {
          url += `&examination=${examtype}&unitFilter=${selectedPatient.refferal_unit},${unitType.id}`;
        } else {
          url += `&examination=${examtype}&unitFilter=${unitType.id}`;
        }
      }

      try {
        const response = await axios.get(Constant.apiUrl.concat(url));
        const interventionDataGet = response.data.data.map((intervention) => {
          return {
            id: intervention._id.toString(),
            name: `${intervention.detail.service} ${
              intervention.detail.description
                ? `- ${intervention.detail.description}`
                : ''
            }`,
            type: intervention.detail.type,
            class: intervention.detail.class,
            main_service: intervention.detail.main_service,
            examination: intervention.detail.examination,
            consumable: _.get(intervention, 'detail.consumable', [])
              .concat(
                _.get(intervention, 'detail.med_tools', []),
                _.get(intervention, 'detail.other_consumable', []),
              )
              .map((arr) => ({
                ...arr,
                selectedItem: '',
                originalQuantity: arr.quantity,
                stockList: arr.med_tools_list || arr.consumable_list,
              })),
            isPackage: intervention.detail.isPackage || false,
            packagedWith: _.get(intervention, 'detail.packagedWith', []).map(
              (ar) => {
                return {
                  id: ar._id.toString(),
                  name: `${ar.detail.service} ${
                    ar.detail.description ? `- ${ar.detail.description}` : ''
                  }`,
                  type: ar.detail.type,
                  class: ar.detail.class,
                  main_service: ar.detail.main_service,
                  examination: ar.detail.examination,
                  consumable: _.get(ar, 'detail.consumable', [])
                    .concat(
                      _.get(ar, 'detail.med_tools', []),
                      _.get(ar, 'detail.other_consumable', []),
                    )
                    .map((arr) => ({
                      ...arr,
                      selectedItem: '',
                      originalQuantity: arr.quantity,
                      stockList: arr.med_tools_list || arr.consumable_list,
                    })),
                };
              },
            ),
          };
        });

        if (isInpatientUnit) {
          let existingResources =
            state.actionsAndConsumables.interventionResourcesInpatient;
          const newResources = interventionDataGet;
          existingResources = existingResources.filter(
            (existItem) =>
              !newResources.some((newItem) => newItem.Id === existItem.id),
          );
          state.actionsAndConsumables.interventionResourcesInpatient =
            existingResources.concat(newResources);
        } else {
          let existingResources =
            state.actionsAndConsumables.interventionResourcesOutpatient;
          const newResources = interventionDataGet;
          existingResources = existingResources.filter(
            (existItem) =>
              !newResources.some((newItem) => newItem.Id === existItem.id),
          );
          state.actionsAndConsumables.interventionResourcesOutpatient =
            existingResources.concat(newResources);
        }

        if (isInpatientUnit) {
          return state.actionsAndConsumables.interventionResourcesInpatient;
        }
        return state.actionsAndConsumables.interventionResourcesOutpatient;
      } catch (error) {
        if (isInpatientUnit) {
          state.actionsAndConsumables.interventionResourcesInpatient = [];
        } else {
          state.actionsAndConsumables.interventionResourcesOutpatient = [];
        }
        return error;
      }
    },
    getDiagnosePlan({ commit }) {
      axios
        .get(`${Constant.apiUrl}/masterdata/nurseplan?type_plan=noplan`)
        .then((response) => {
          const planList = response.data.data.map((arr) => ({
            text: arr.plan,
            diagnose: arr.diagnose,
            diagnose_number: arr.diagnose_number,
            plan_number: arr.plan_number,
            id_plan: arr._id,
            is_additional_data: arr.allowAdditionalData
              ? arr.allowAdditionalData
              : false,
          }));
          commit('saveNursePlan', planList);
          const diagnosis = response.data.data
            .filter(
              (arr, index) =>
                index ===
                response.data.data.findIndex(
                  (ar) => ar.diagnose === arr.diagnose,
                ),
            )
            .map((arr) => ({
              text: arr.diagnose,
              diagnose: arr.diagnose,
              diagnose_number: arr.diagnose_number,
            }));
          commit('saveNurseDiagnose', diagnosis);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeBedStatus({ getters }) {
      return axios.post(
        Constant.apiUrl
          .concat('/masterdata/bedChangeStatus/')
          .concat(getters.inpatientBedPick.pickedBed.id)
          .concat('/')
          .concat(getters.inpatientBedPick.pickedBed.fixWard)
          .concat('/')
          .concat(getters.inpatientBedPick.pickedBed.fixRoom.room_number),
        {
          availability: 'occupy',
          occupant_gender:
            getters.newPatientTempSocialData.gender === '1' ? 'M' : 'F',
          isOutCapacity: false,
          isOutService: false,
        },
      );
    },
    registerNewInPatientQueue({ getters }) {
      let paymentInput;
      if (Number(this.state.newPatientTemp.paymentType.type) === 6) {
        paymentInput = {
          type: Number(this.state.newPatientTemp.paymentType.type),
          detail: {
            chosen: this.state.newPatientTemp.paymentType.detail.chosen,
            data: this.state.newPatientTemp.paymentType.detail.data,
          },
        };
      } else {
        paymentInput = {
          type: Number(this.state.newPatientTemp.paymentType.type),
          detail: {
            id_insurance: this.state.newPatientTemp.paymentType.detail.id,
            chosen: this.state.newPatientTemp.paymentType.detail.chosen,
            data: this.state.newPatientTemp.paymentType.detail.data,
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/inpatient/queue/inpatientstdetailpush/')
          .concat(getters.selectedPatient.id_queue),
        {
          detail_register: [
            {
              id_waiting_list: getters.selectedPatient.id_waiting_list,
              bed: [
                {
                  id_bed: getters.inpatientBedPick.pickedBed.id,
                  room: getters.inpatientBedPick.pickedBed.fixRoom.room_number,
                  ward: getters.inpatientBedPick.pickedBed.fixWard,
                  class: getters.inpatientBedPick.pickedBed.fixRoom.roomClass,
                  bed: getters.inpatientBedPick.pickedBed.name,
                  status: getters.inpatientBedPick.pickedStatus,
                },
              ],
              payment: paymentInput,
              referrence: {
                ref_no:
                  typeof getters.newPatientTempSocialData.bpjs.referenceNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.referenceNo
                    : getters.newPatientTempSocialData.bpjs.referenceNo.value,
                skdp_no:
                  (typeof getters.newPatientTempSocialData.bpjs.skdpNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.skdpNo
                    : getters.newPatientTempSocialData.bpjs.skdpNo.text) || '',
                instance: this.state.newPatientTemp.paymentType.detail.chosen,
                person_name: '',
              },

              timestamps: {
                created_by: getters.userLoggedIn.id,
              },
            },
          ],
        },
      );
    },
    registerNewPatient({ state, getters }) {
      let bpjsInput;
      console.log(state, getters);
      if (getters.chooseService === 2) {
        bpjsInput = {
          bpjs_no: getters.newPatientTempSocialData.bpjs.cardNo,
          health_facility_level: 0,
          diagnosis: '',
          sep_data: '',
          reference_no: '',
          skdp: '',
          phone: '',
          note: '',
          accident_status: 1,
          ppk: '',
          speialis: '',
        };
      } else {
        bpjsInput = {
          bpjs_no: getters.newPatientTempSocialData.bpjs.cardNo,
          health_facility_level: Number(
            getters.newPatientTempSocialData.bpjs.originReference,
          ),
          diagnosis: getters.newPatientTempSocialData.bpjs.diagnosis,
          sep_date: getters.newPatientTempSocialData.bpjs.sepDob,
          reference_no:
            typeof getters.newPatientTempSocialData.bpjs.referenceNo ===
            'string'
              ? getters.newPatientTempSocialData.bpjs.referenceNo
              : getters.newPatientTempSocialData.bpjs.referenceNo.value,
          // reference_date: getters.newPatientTempSocialData.bpjs.referenceDate,
          skdp:
            (typeof getters.newPatientTempSocialData.bpjs.skdpNo === 'string'
              ? getters.newPatientTempSocialData.bpjs.skdpNo
              : getters.newPatientTempSocialData.bpjs.skdpNo.text) || '',
          phone: getters.newPatientTempSocialData.bpjs.phoneNo,
          note: getters.newPatientTempSocialData.bpjs.note,
          accident_status: getters.newPatientTempSocialData.bpjs.accidentStatus,
          ppk: getters.newPatientTempSocialData.bpjs.ppk,
          speisalis: getters.newPatientTempSocialData.bpjs.subSpesialisName,
          no_sep: getters.newPatientTempSocialData.bpjs.noSep,
          doctor: getters.newPatientTempSocialData.bpjs.doctor,
        };
      }
      return axios.post(Constant.apiUrl.concat('/patient/register'), {
        rm_patient: state.newPatientTemp.socialData.noMr,
        isOldPatient: state.newPatientTemp.socialData.isOldPatient,
        detail: [
          {
            // TODO: Create getters that link to new fingerprint register
            finger_print: '',
            name: getters.newPatientTempSocialData.name,
            user_title: Number(getters.newPatientTempSocialData.status),
            gender: getters.newPatientTempSocialData.gender === '1',
            birth: {
              city: getters.newPatientTempSocialData.pob,
              date: getters.newPatientTempSocialData.dob,
            },
            address: {
              id_regency: getters.newPatientTempSocialData.id_regency,
              id_province: getters.newPatientTempSocialData.id_province,
              id_district: getters.newPatientTempSocialData.id_district,
              id_subdistrict: getters.newPatientTempSocialData.id_subdistrict,
              detail: getters.newPatientTempSocialData.address,
              // TODO: confirmation of postal code input in form
              postal_code: null,
            },
            phone_no: getters.newPatientTempSocialData.phone,
            identity: {
              type: Number(getters.newPatientTempSocialData.identity.type),
              number: getters.newPatientTempSocialData.identity.number,
            },
            priority_card: {
              type: Number(getters.newPatientTempSocialData.priority_card.type),
              number: getters.newPatientTempSocialData.priority_card.number,
            },
            religion: Number(getters.newPatientTempSocialData.religion),
            blood_type: Number(getters.newPatientTempSocialData.bloodType),
            education: Number(getters.newPatientTempSocialData.education),
            job: getters.newPatientTempSocialData.job,
            married_status: Number(
              getters.newPatientTempSocialData.marriedStatus,
            ),
            parents: {
              father: getters.newPatientTempSocialData.fatherName,
              mother: getters.newPatientTempSocialData.motherName,
            },
            bpjs: bpjsInput,
            pic:
              getters.newPatientTempSocialData.pic.name !== ''
                ? {
                    name: getters.newPatientTempSocialData.pic.name,
                    phone_no: getters.newPatientTempSocialData.pic.phone,
                    address: getters.newPatientTempSocialData.pic.address,
                  }
                : '',
            payment_pic:
              getters.newPatientTempSocialData.paymentPic.name !== ''
                ? {
                    name: getters.newPatientTempSocialData.paymentPic.name,
                    phone_no: getters.newPatientTempSocialData.paymentPic.phone,
                    address:
                      getters.newPatientTempSocialData.paymentPic.address,
                  }
                : '',
            etnic: getters.newPatientTempSocialData.ethnicity,
            language: getters.newPatientTempSocialData.language,
            email: getters.newPatientTempSocialData.email,
            communication_barriers:
              getters.newPatientTempSocialData.communicationObstacles,
            disabilities: getters.newPatientTempSocialData.disability,
            status: Number(getters.newPatientTempSocialData.status),
            // TODO: add element for PIC
            timestamps: {
              created_by: getters.userLoggedIn.id,
            },
          },
        ],
      });
    },
    registerNewPatientQueue({ getters }) {
      let paymentInput;
      const date =
        getters.chooseService === 2
          ? ''
          : moment(getters.newPatientTempPolyAndDoctor.date).format(
              'YYYY-MM-DD',
            );
      const time =
        getters.chooseService === 2 ? '' : moment(new Date()).format('HH:mm');
      const momentObj =
        getters.chooseService === 2 ? '' : moment(date + time, 'YYYY-MM-DDLT');

      if (Number(getters.newPatientTempPayment.type) === 6) {
        paymentInput = {
          type: Number(getters.newPatientTempPayment.type),
          detail: {
            chosen: getters.newPatientTempPayment.detail.chosen,
            data: getters.newPatientTempPayment.detail.data,
          },
        };
      } else {
        paymentInput = {
          type: Number(getters.newPatientTempPayment.type),
          detail: {
            id_insurance: getters.newPatientTempPayment.detail.id,
            chosen: getters.newPatientTempPayment.detail.chosen,
            data: getters.newPatientTempPayment.detail.data,
          },
        };
      }
      if (getters.isIgd) {
        const payload = {
          id_patient: getters.newPatientTempSocialData._id,
          registration_number: 2,
          service_type: getters.chooseService, // 1: PILIH POLY, 2: PILIH PENUNJANG, 3: PILIH PENUNJANG & POLY
          detail: [
            {
              clinic: {
                id: getters.newPatientTempPolyAndDoctor.clinic.id,
              },
              payment: paymentInput,
              referrence: {
                instance:
                  typeof getters.newPatientTempSocialData.bpjs
                    .hospitalReference !== 'object'
                    ? ''
                    : getters.newPatientTempSocialData.bpjs.hospitalReference
                        .value,
                person_name:
                  typeof getters.newPatientTempSocialData.bpjs.doctorName !==
                  'string'
                    ? ''
                    : getters.newPatientTempSocialData.bpjs.doctorName,
                ref_no:
                  typeof getters.newPatientTempSocialData.bpjs.referenceNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.referenceNo
                    : getters.newPatientTempSocialData.bpjs.referenceNo.value,
                skdp_no:
                  (typeof getters.newPatientTempSocialData.bpjs.skdpNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.skdpNo
                    : getters.newPatientTempSocialData.bpjs.skdpNo.text) || '',
              },
              timestamps: {
                created_by: getters.userLoggedIn.id,
              },
            },
          ],
        };
        if (getters.isIgdBpjs) {
          payload.detail[0].entryMethod =
            getters.newPatientTempPolyAndDoctor.entryMethod;
        }
        return axios.post(
          Constant.apiUrl.concat(`/patient/igd/register/${getters.idTriase}`),
          payload,
        );
      }
      if (getters.chooseService === 3) {
        return axios.post(Constant.apiUrl.concat('/patient/queue/register'), {
          start_admision: getters.newPatientTempstartAdmision,
          id_patient: getters.newPatientTempSocialData._id,
          registration_number: 2,
          registered_at: moment(momentObj).format(),
          service_type: getters.chooseService, // 1: PILIH POLY, 2: PILIH PENUNJANG, 3: PILIH PENUNJANG & POLY
          detail: [
            {
              support_service: {
                // JIKA PILIH SERVICE_TYPE 2 / 3 DISI, JIKA TIDAK DIABAIKAN
                id: getters.newPatientTempSupport.supportService.id,
              },
              clinic: {
                id: getters.newPatientTempPolyAndDoctor.clinic.id,
                doctor: getters.newPatientTempPolyAndDoctor.doctor.id,
                checkin_start_time:
                  getters.newPatientTempPolyAndDoctor.start_time,
                checkin_end_time: getters.newPatientTempPolyAndDoctor.end_time,
                room_description:
                  getters.newPatientTempPolyAndDoctor.roomDescription,
              },
              entryMethod: getters.newPatientTempPolyAndDoctor.entryMethod,
              payment: paymentInput,
              referrence: {
                instance:
                  typeof getters.newPatientTempSocialData.bpjs
                    .hospitalReference !== 'object'
                    ? ''
                    : getters.newPatientTempSocialData.bpjs.hospitalReference
                        .value,
                person_name:
                  typeof getters.newPatientTempSocialData.bpjs.doctorName !==
                  'string'
                    ? ''
                    : getters.newPatientTempSocialData.bpjs.doctorName,
                ref_no:
                  typeof getters.newPatientTempSocialData.bpjs.referenceNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.referenceNo
                    : getters.newPatientTempSocialData.bpjs.referenceNo.value,
                skdp_no:
                  (typeof getters.newPatientTempSocialData.bpjs.skdpNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.skdpNo
                    : getters.newPatientTempSocialData.bpjs.skdpNo.text) || '',
              },
              timestamps: {
                created_by: getters.userLoggedIn.id,
              },
            },
          ],
        });
      }
      if (getters.chooseService === 2) {
        return axios.post(Constant.apiUrl.concat('/patient/queue/register'), {
          start_admision: getters.newPatientTempstartAdmision,
          id_patient: getters.newPatientTempSocialData._id,
          registration_number: 2,
          service_type: getters.chooseService, // 1: PILIH POLY, 2: PILIH PENUNJANG, 3: PILIH PENUNJANG & POLY
          detail: [
            {
              support_service: {
                // JIKA PILIH SERVICE_TYPE 2 / 3 DISI, JIKA TIDAK DIABAIKAN
                id: getters.newPatientTempSupport.supportService.id,
              },
              payment: paymentInput,
              referrence: {
                instance:
                  typeof getters.newPatientTempSocialData.bpjs
                    .hospitalReference !== 'object'
                    ? ''
                    : getters.newPatientTempSocialData.bpjs.hospitalReference
                        .value,
                person_name:
                  typeof getters.newPatientTempSocialData.bpjs.doctorName !==
                  'string'
                    ? ''
                    : getters.newPatientTempSocialData.bpjs.doctorName,
                ref_no:
                  typeof getters.newPatientTempSocialData.bpjs.referenceNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.referenceNo
                    : getters.newPatientTempSocialData.bpjs.referenceNo.value,
                skdp_no:
                  (typeof getters.newPatientTempSocialData.bpjs.skdpNo ===
                  'string'
                    ? getters.newPatientTempSocialData.bpjs.skdpNo
                    : getters.newPatientTempSocialData.bpjs.skdpNo.text) || '',
              },
              timestamps: {
                created_by: getters.userLoggedIn.id,
              },
            },
          ],
        });
      }
      console.log('check dc', getters.newPatientTempPolyAndDoctor);
      const roomDescTemp =
        getters.newPatientTempPolyAndDoctor.doctor.schedules.find(
          (arr) =>
            moment(getters.newPatientTempPolyAndDoctor.date).format(
              'YYYY-MM-DD',
            ) === arr.date &&
            arr.start_time === getters.newPatientTempPolyAndDoctor.start_time &&
            arr.end_time === getters.newPatientTempPolyAndDoctor.end_time,
        );
      console.log('test room', roomDescTemp);
      return axios.post(Constant.apiUrl.concat('/patient/queue/register'), {
        start_admision: getters.newPatientTempstartAdmision,
        id_patient: getters.newPatientTempSocialData._id,
        registration_number: 2,
        service_type: getters.chooseService, // 1: PILIH POLY, 2: PILIH PENUNJANG, 3: PILIH PENUNJANG & POLY
        registered_at: moment(momentObj).format(),
        detail: [
          {
            clinic: {
              id: getters.newPatientTempPolyAndDoctor.clinic.id,
              doctor: getters.newPatientTempPolyAndDoctor.doctor.id,
              checkin_start_time:
                getters.newPatientTempPolyAndDoctor.start_time,
              checkin_end_time: getters.newPatientTempPolyAndDoctor.end_time,
              room_description:
                getters.newPatientTempPolyAndDoctor.entryMethod !== 'born'
                  ? roomDescTemp.room_description
                  : getters.newPatientTempPolyAndDoctor.roomDescription,
            },
            entryMethod: getters.newPatientTempPolyAndDoctor.entryMethod,
            payment: paymentInput,
            referrence: {
              instance:
                typeof getters.newPatientTempSocialData.bpjs
                  .hospitalReference !== 'object'
                  ? ''
                  : getters.newPatientTempSocialData.bpjs.hospitalReference
                      .value,
              person_name:
                typeof getters.newPatientTempSocialData.bpjs.doctorName !==
                'string'
                  ? ''
                  : getters.newPatientTempSocialData.bpjs.doctorName,
              ref_no:
                typeof getters.newPatientTempSocialData.bpjs.referenceNo ===
                'string'
                  ? getters.newPatientTempSocialData.bpjs.referenceNo
                  : getters.newPatientTempSocialData.bpjs.referenceNo.value,
              skdp_no:
                (typeof getters.newPatientTempSocialData.bpjs.skdpNo ===
                'string'
                  ? getters.newPatientTempSocialData.bpjs.skdpNo
                  : getters.newPatientTempSocialData.bpjs.skdpNo.text) || '',
            },
            timestamps: {
              created_by: getters.userLoggedIn.id,
            },
          },
        ],
      });
    },
    postDrugReconciliation({ getters }) {
      if (getters.isJwtVerified) {
        return new Promise((resolve, reject) => {
          axios
            .post(
              Constant.apiUrl
                .concat('/patient/drug/reconciliation/')
                .concat(getters.selectedPatient.id_queue),
              {
                detail: [
                  {
                    detail_recon: getters.drugReconciliation.drugs.map(
                      (data) => {
                        return {
                          drug_name: data.drug,
                          Dosage: {
                            type: 0,
                            total: data.dose,
                          },
                          term_of_usage: data.usage,
                          clinic_no: 22,
                          status: data.isContinue,
                          information: data.detail,
                          timestamps: {
                            created_by: getters.userLoggedIn.id,
                          },
                        };
                      },
                    ),
                  },
                ],
              },
            )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
      return Promise.reject(new Error('Token sudah tidak berlaku'));
    },
    saveEmergencyClinicTriase({ getters }) {
      return axios.post(Constant.apiUrl.concat('/patient/igd/triase'), {
        detail_triase: [
          {
            id_poly: '5d1ba743f916250cd0372edb',
            filled_at: moment().format(),
            triase_level: {
              name: 'name',
              gender: true,
              person_in_charge: 'pic',
              address: 'add',
              parent_name: 'parent',
              pacs: 1,
              awareness: 1,
              blood_pressure: 1,
              temperature: 1,
              pulse: 1,
              saturation: 1,
              respiratory: 1,
              pain: 'paint',
              weight_body: 1,
              height_body: 1,
            },
            wpss: {
              awareness: 1,
              blood_pressure_sistolic: 1,
              pulse: 1,
              respiratory: 1,
              saturation: 1,
              total: 1,
              note: 'note',
              decision: 'ido',
            },
            triase: {
              entry: '',
              mounted: [''],
              reason: {
                type: 'polisi',
                info: '',
              },
              vehicle: 'abc',
              agent: 'abc',
              arrival_condition: [1],
              trauma_mechanism: {
                individual_accident: {
                  status: true,
                  place: 'abc',
                  date: 'abc',
                  time: 'abc',
                },
                accident_between: {
                  status: true,
                  vehicle1: 'abc',
                  vehicle2: 'abc',
                  place: 'abc',
                  date: 'abc',
                  time: 'abc',
                },
                fall_trauma: {
                  status: true,
                  info: 'abc',
                },
                burns_trauma: {
                  status: true,
                  info: 'abc',
                },
                electric_trauma: {
                  status: true,
                  info: 'abc',
                },
                substance_trauma: {
                  status: true,
                  info: 'abc',
                },
                icd_10: '5e0ef387b287ce38fc5097bf',
                primary_complaint: 'abc',
              },
              timestamps: {
                created_by: getters.userLoggedIn.id,
              },
            },
          },
        ],
      });
    },
    postDrugRecipe({ getters }) {
      if (getters.isJwtVerified) {
        return new Promise((resolve, reject) => {
          axios
            .post(
              Constant.apiUrl
                .concat('/patient/drug/receipt/')
                .concat(getters.selectedPatient.id_queue),
              {
                detail: [
                  {
                    R: getters.drugRecipe.map((drug) => {
                      return {
                        item: drug.id,
                        strength: Number(drug.power),
                        racic_dose: drug.dose,
                        request: Number(drug.request),
                        rules: drug.usage,
                        general_rule: drug.generalRuleValue,
                        special_rule: drug.specialRuleValue,
                        another_rule: drug.anotherRule,
                        how_to_make: drug.wayOfMaking,
                        price: drug.price,
                        sub_total: drug.subTotal,
                        timestamps: {
                          created_by: getters.userLoggedIn.id,
                        },
                      };
                    }),
                  },
                ],
              },
            )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
      return Promise.reject(new Error('Token sudah tidak berlaku'));
    },
  },
});
