import {
  getConfigApprovalBackdateJournal,
  getConfigApprovalDailyJournal,
} from '../../../fetchAPI/finance';

const state = {
  configApprovalBackdateJournal: { role: [] },
  configApprovalDailyJournal: { role: [] },
};

const getters = {
  getConfigApprovalBackdateJournal: (state) =>
    state.configApprovalBackdateJournal,
  getConfigApprovalDailyJournal: (state) => state.configApprovalDailyJournal,
};

const mutations = {
  setConfigApprovalBackdateJournal(state, payload) {
    state.configApprovalBackdateJournal = payload;
  },
  setConfigApprovalDailyJournal(state, payload) {
    state.configApprovalDailyJournal = payload;
  },
};

const actions = {
  async resolveGetConfigApprovalBackdateJournal({ commit }) {
    try {
      const responseConfigApprovalBackdateJournal =
        await getConfigApprovalBackdateJournal();

      const mapGetConfigApprovalBackdateJournal = JSON.parse(
        responseConfigApprovalBackdateJournal.value,
      );
      commit(
        'setConfigApprovalBackdateJournal',
        mapGetConfigApprovalBackdateJournal,
      );
      return responseConfigApprovalBackdateJournal;
    } catch (error) {
      return error;
    }
  },
  async resolveGetConfigApprovalDailyJournal({ commit }) {
    try {
      const responseConfigApprovalDailyJournal =
        await getConfigApprovalDailyJournal();

      const mapGetConfigApprovalDailyJournal = JSON.parse(
        responseConfigApprovalDailyJournal.value,
      );
      commit('setConfigApprovalDailyJournal', mapGetConfigApprovalDailyJournal);
      return responseConfigApprovalDailyJournal;
    } catch (error) {
      return error;
    }
  },
};

export default { state, getters, mutations, actions };
